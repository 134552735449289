<script>
import { adminComputed, orderComputed } from "@/state/helpers";
export default {
    data: () => ({}),
    computed: {
        ...adminComputed,
        ...orderComputed,
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.page-break {
    display: block;
    page-break-before: always;
}
.avoid-page-break {
    display: table;
    width: 100%;
}
.avoid-page-break tbody {
    display: table-row-group;
}
.avoid-inside-page-break {
    page-break-inside: avoid;
}
</style>

<template>
    <b-card id="printCustomerLabel">
        <b-row class="mb-3 d-flex justify-content-between">
            <b-col cols="12">
                <b-row>
                    <b-col cols="6 mr-5">
                        <h2 class="font-weight-bold">FROM:</h2>
                        <h5>
                            {{ core.site_config.company_name }}
                        </h5>

                        <div class="d-flex flex-column">
                            <span>{{
                                selected_order_details.branch.address
                            }}</span>
                            <span>{{
                                selected_order_details.branch.name
                            }}</span>
                            <span>{{
                                selected_order_details.branch.contact_no
                            }}</span>
                        </div>

                        <b-form-group
                            class="mt-3"
                            label="Order Number"
                            label-for="person-input"
                        >
                            <b-form-input
                                v-model="selected_order_details.order_no"
                                size="sm"
                                disabled
                                type="text"
                            ></b-form-input
                        ></b-form-group>
                    </b-col>
                    <b-col cols="3" class="text-center mt-3">
                        <img
                            src="@/assets/images/client_logo.webp"
                            alt
                            height="125"
                        />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <hr />

        <h2 class="font-weight-bold">DELIVER TO:</h2>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="3">
                        <b-form-group
                            label="Company name"
                            label-for="person-input"
                        >
                            <b-form-input
                                v-model="
                                    selected_order_details.client.company_name
                                "
                                size="sm"
                                disabled
                                type="text"
                            ></b-form-input
                        ></b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group label="Phone" label-for="person-input">
                            <b-form-input
                                v-model="
                                    selected_order_details.client.contact_no
                                "
                                size="sm"
                                disabled
                                type="text"
                            ></b-form-input
                        ></b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="3">
                        <b-form-group
                            label="Contact Person"
                            label-for="person-input"
                        >
                            <b-form-input
                                v-model="
                                    selected_order_details.client.contact_person
                                "
                                size="sm"
                                disabled
                                type="text"
                            ></b-form-input
                        ></b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group label="Email" label-for="person-input">
                            <b-form-input
                                v-model="selected_order_details.client.emails"
                                size="sm"
                                disabled
                                type="text"
                            ></b-form-input
                        ></b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="9">
                        <b-form-group label="Address" label-for="person-input">
                            <b-form-input
                                v-model="selected_order_details.client.address"
                                size="sm"
                                disabled
                                type="text"
                            ></b-form-input
                        ></b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-row>
                    <b-col cols="3">
                        <b-form-group label="City" label-for="person-input">
                            <b-form-input
                                v-model="selected_order_details.client.city"
                                size="sm"
                                disabled
                                type="text"
                            ></b-form-input
                        ></b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group
                            label="Postal Code"
                            label-for="person-input"
                        >
                            <b-form-input
                                v-model="
                                    selected_order_details.client.postal_code
                                "
                                size="sm"
                                disabled
                                type="text"
                            ></b-form-input
                        ></b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group label="Province" label-for="person-input">
                            <b-form-input
                                v-model="selected_order_details.client.province"
                                size="sm"
                                disabled
                                type="text"
                            ></b-form-input
                        ></b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <hr />
    </b-card>
</template>
