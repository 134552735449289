<template>
    <b-card id="printOrder">
        <b-row class="mb-3">
            <b-col cols="3" class="text-center">
                <img src="@/assets/images/client_logo.webp" alt height="150" />
            </b-col>
            <b-col>
                <b-row>
                    <b-col cols="3">
                        <b-form-group label="Order Number" label-for="person-input">
                            <b-form-input v-model="selected_order_details.order_no" size="sm" disabled type="text"></b-form-input
                        ></b-form-group>
                    </b-col>
                    <b-col cols="3">
                        <b-form-group label="Due date" label-for="person-input">
                            <b-form-input v-model="sliceDate" size="sm" disabled type="text"></b-form-input
                        ></b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <hr />

        <h4 class="mb-4">Order Details:</h4>

        <div class="void-page-break">
            <ordersPrintOrder />
        </div>

        <hr />
        <!-- section for materials -->
        <!-- <div class="avoid-inside-page-break">
            <h4 class="mb-4 mt-4">Materials:</h4>

            <div v-for="property in selected_order_details.properties" :key="property.id">
                <b-form-group :label="property.name">
                    <b-form-textarea v-model="property.value"></b-form-textarea>
                </b-form-group>
            </div>
        </div> -->

        <!-- section for quality control -->
        <div class="avoid-inside-page-break">
            <h4 class="mb-4 mt-4">Quality Control:</h4>

            <b-row>
                <b-col>
                    <h6>Quality control inspector 1:</h6>
                    <b-row>
                        <b-col cols="3">
                            <b-form-group label="Name" label-for="person-input">
                                <b-form-input size="sm" disabled type="text"></b-form-input
                            ></b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="Signature" label-for="person-input">
                                <b-form-input size="sm" disabled type="text"></b-form-input
                            ></b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="Date" label-for="person-input">
                                <b-form-input size="sm" disabled type="text"></b-form-input
                            ></b-form-group>
                        </b-col>
                    </b-row>

                    <h6>Quality control inspector 2:</h6>
                    <b-row>
                        <b-col cols="3">
                            <b-form-group label="Name" label-for="person-input">
                                <b-form-input size="sm" disabled type="text"></b-form-input
                            ></b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="Signature" label-for="person-input">
                                <b-form-input size="sm" disabled type="text"></b-form-input
                            ></b-form-group>
                        </b-col>
                        <b-col cols="3">
                            <b-form-group label="Date" label-for="person-input">
                                <b-form-input size="sm" disabled type="text"></b-form-input
                            ></b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </div>
    </b-card>
</template>

<script>
import { adminComputed, orderComputed } from "@/state/helpers";
import ordersPrintOrder from "./ordersPrintOrder";

export default {
    components: {
        ordersPrintOrder,
    },
    data: () => ({}),
    computed: {
        ...adminComputed,
        ...orderComputed,
        sliceDate() {
            return this.selected_order_details.due_date.slice(0, 10);
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.page-break {
    display: block;
    page-break-before: always;
}
.avoid-page-break {
    display: table;
    width: 100%;
}
.avoid-page-break tbody {
    display: table-row-group;
}
.avoid-inside-page-break {
    page-break-inside: avoid;
}
</style>
